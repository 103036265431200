<script lang="ts">
export default {
  name: 'app-login-modal'
};
</script>

<script lang="ts" setup>
import { useModalStore } from '~/store/modal';
import { useSpinnerStore } from '@/store/spinner';

const modalStore = useModalStore();
const spinnerStore = useSpinnerStore();

const { hideModal,modal } = modalStore;
const { login,isAuthenticated } = useSanctumAuth();

const route=useRoute();
const uuid= uuis();

const selected= ref(2);
const error= reactive({
  login: undefined,
  register: undefined,
  recovery: undefined
});
const recoveryMailSent= ref(false);
/** Login*/
const fieldsLogin = {
    email: {
        value: '',
        validations: ['required','email']
    },
    password: {
        value: '',
        validations: ['required']
    }
};


const { formData: formDataLogin, formStates:formStatesLogin , validateData: validateDataLogin } = useFormsMa(fieldsLogin);

/** Registro*/
const fieldsRegister = {
    nombre: {
        value: '',
        validations: ['required', 'string']
    },
    apellidos: {
        value: '',
        validations: ['required', 'string']
    },
    email: {
        value: '',
        validations: ['required','email']
    },
    password: {
        value: '',
        validations: ['required','password']
    },
    acepta_gdpr_1: {
        value: false,
        validations: ['required']
    },
    acepta_gdpr_2: {
        value: false,
        validations: []
    }
};


const { formData: formDataRegister, formStates:formStatesRegister , validateData: validateDataRegister } = useFormsMa(fieldsRegister);

/** Reset Contraseña*/
const fieldsReset = {
    email: {
        value: '',
        validations: ['required','email']
    }
};


const { formData: formDataReset, formStates:formStatesReset , validateData: validateDataReset } = useFormsMa(fieldsReset);






const toggleSelected = (index:number) => {
  if(selected.value!==index){
    selected.value=index;
    
  }
};

/** Login*/
async function loginUser() {

    const { isOk, formData } = validateDataLogin();

    if (isOk) {
        try{
          spinnerStore.showSpinner();
          await login(formData);
        } catch(e) {
          error.login=e.data.message;
          spinnerStore.hideSpinner();
        }

    }
}

/** Registro*/
async function register() {
    const { $api } = useNuxtApp();
    const { isOk, formData } = validateDataRegister();
    
  
    if (isOk) {
        spinnerStore.showSpinner();
        const { data, status, error:errorApi } = await $api.user.store(formData);

        if (errorApi.value) {
          spinnerStore.hideSpinner();
          error.register=errorApi.value.data?.message;
        }else{
          try{
            await login(formData);
            await navigateTo({path: '/panel'});
            hideModal();
            spinnerStore.hideSpinner();
          } catch(e) {
            spinnerStore.hideSpinner();
          }
        }
    }


}

/** New Password*/
async function resetPassword() {
    const router = useRouter();
    const url = useRequestURL();
    const { $api } = useNuxtApp();
    const { isOk, formData } = validateDataReset();

    if (isOk) {
        spinnerStore.showSpinner();
        const { data, status, error:errorApi } = await $api.user.recoveryPassword(formData);

        if (errorApi.value) {
          spinnerStore.hideSpinner();
          recoveryMailSent.value=false;
          error.recovery='Email no encontrado';
        }else{
          recoveryMailSent.value=true;
          spinnerStore.hideSpinner();
        }
    }

}

watch(isAuthenticated,()=>{
  if(modal.showModal){
    hideModal();
  }
  if(spinnerStore.show){
    spinnerStore.hideSpinner();
  }
});

</script>

<template lang="pug">
.app-brand-login-modal.modal-custom__inner
  .app-brand-login-modal__header 
    span.icon-times.ms-auto(@click="hideModal()")
  hr.my-3.my-lg-5
  .app-brand-login-modal__body.text-center
    span.icon-hand(:class="{'ico': !modal.props.isBrand ,'ico-brand': modal.props.isBrand}")
    h3.lead.mt-3 ¡NO TE PIERDAS LAS VENTAJAS DE TENER UNA CUENTA!
    p(v-if="selected===1") Saca el máximo partido a tu cuenta. Ten a mano tus búsquedas, tus favoritos, tus alertas... ¡y más!
    p(v-else) Accede a tu cuenta y revisa tus últimos movimientos.

    ul.d-flex.align-items-center.justify-content-center.gap-3
      li(
        @click="toggleSelected(1)",
        :class="{'app-brand-login-modal__body--option-selected': selected === 1 && !modal.props.isBrand,'app-brand-login-modal__body--option-selected-brand': selected === 1 && modal.props.isBrand, 'app-brand-login-modal__body--option-not-selected': selected !== 1 }",
        style="cursor: pointer"
      ) CREAR CUENTA
      li(
        @click="toggleSelected(2)",
        :class="{'app-brand-login-modal__body--option-selected': selected !== 1 && !modal.props.isBrand,'app-brand-login-modal__body--option-selected-brand': selected !== 1 && modal.props.isBrand, 'app-brand-login-modal__body--option-not-selected': selected === 1}",
        style="cursor: pointer"
      ) YA TENGO CUENTA
    .mt-4
      form(v-if="selected==1")
        .row.justify-content-center
            .col-6 
                .form-floating.mb-3
                    input.form-control.form-control--border-primary(
                    type="text", 
                    :id="`name${uuid}`",
                    placeholder="Nombre",
                    v-model="formDataRegister.nombre")
                    label(:for="`name${uuid}`") Nombre
                    span.text-danger(v-if="formStatesRegister.nombre") {{ formStatesRegister.nombre }}
            .col-6
                .form-floating.mb-3
                    input.form-control.form-control--border-primary(
                    type="text", 
                    :id="`last-name${uuid}`",
                    placeholder="Apellidos",
                    v-model="formDataRegister.apellidos")
                    label(:for="`last-name${uuid}`") Apellidos
                    span.text-danger(v-if="formStatesRegister.apellidos") {{ formStatesRegister.apellidos }}
        .row.justify-content-center 
            .col-12
                .form-floating.w-100.mb-3
                    input.form-control.form-control--border-primary(
                    type="email", 
                    :id="`email${uuid}`",
                    placeholder="Email",
                    v-model="formDataRegister.email")
                    label(:for="`email${uuid}`") Email
                    span.text-danger(v-if="formStatesRegister.email") {{ formStatesRegister.email }} 
                .form-floating.w-100.mb-3
                    input.form-control.form-control--border-primary(
                    type="password", 
                    :id="`password${uuid}`",
                    placeholder="Password",
                    v-model="formDataRegister.password")
                    label(:for="`password${uuid}`") Password
                    span.text-danger(v-if="formStatesRegister.password") {{ formStatesRegister.password }} 
        .row.justify-content-center
            .col-12 
                .form-check.mb-1
                    input.form-check-input(
                    type="checkbox", 
                    :id="`acceptances${uuid}`",
                    v-model="formDataRegister.acepta_gdpr_1")
                    label(for="acceptances")  He leído y acepto los 
                      nuxt-link.termsLink(target="_blank", :to="(route.params.marca ? `/${route.params.marca}` : '' ) + '/politica-de-privacidad/'") términos y condiciones de la política de privacidad.
                    span.d-block.text-danger(v-if="formStatesRegister.acepta_gdpr_1") {{ formStatesRegister.acepta_gdpr_1 }}
            .col-12 
                .form-check
                    input.form-check-input(
                    type="checkbox", 
                    :id="`consent${uuid}`",
                    v-model="formDataRegister.acepta_gdpr_2")
                    label(for="consent") Doy mi consentimiento para el tratamiento de mis datos personales con fines de marketing y comerciales (Opcional)
        .row.justify-content-center 
            .col-12.text-center.mt-4 
              button(@click.prevent="register()" :class="{'btn btn--primary btn--large': !modal.props.isBrand ,'btn-brand btn-brand--cta btn-brand--large': modal.props.isBrand}" type="submit") CONTINUAR
            .col-12.text-center.mt-2 
              span.text-danger(v-if="error.register") {{error.register}} 

      form(v-if="selected==2")
        .row.justify-content-center 
            .col-12
                .form-floating.w-100.mb-3
                    input.form-control.form-control--border-primary(
                    type="email", 
                    :id="`email${uuid}`",
                    placeholder="Email",
                    v-model="formDataLogin.email")
                    label(:for="`email${uuid}`") Email
                    span.text-danger(v-if="formStatesLogin.email") {{ formStatesLogin.email }} 
                .form-floating.w-100.mb-3
                    input.form-control.form-control--border-primary(
                    type="password", 
                    :id="`password${uuid}`",
                    placeholder="Password",
                    v-model="formDataLogin.password")
                    label(:for="`password${uuid}`") Password
                    span.text-danger(v-if="formStatesLogin.password") {{ formStatesLogin.password }} 
        .row.justify-content-center 
            .col-12.text-center.mt-4 
              button(@click.prevent="loginUser()" :class="{'btn btn--primary btn--large': !modal.props.isBrand ,'btn-brand btn-brand--cta btn-brand--large': modal.props.isBrand}" type="submit") INICIAR SESIÓN
            .col-12.text-center.mt-2 
              span.text-danger(v-if="error.login") {{error.login}} 
        .row.justify-content-center.mt-3(v-if="selected==2")
          button.secondary-option(@click="toggleSelected(3)") He olvidado mi contraseña

      form(v-if="selected==3")
        .row.justify-content-center 
            .col-12
                .form-floating.w-100.mb-3
                    input.form-control.form-control--border-primary(
                    type="email", 
                    :id="`email${uuid}`",
                    placeholder="Email",
                    v-model="formDataReset.email")
                    label(:for="`email${uuid}`") Email
                    span.text-danger(v-if="formStatesReset.email") {{ formStatesReset.email }} 
        .row.justify-content-center
            .col-12.text-center.mt-4 
              button(v-if="!recoveryMailSent" @click.prevent="resetPassword()" :class="{'btn btn--primary btn--large': !modal.props.isBrand ,'btn-brand btn-brand--cta btn-brand--large': modal.props.isBrand}" type="submit") CONTINUAR
              span.text-success.mt-2(v-else) Tu solicitud ha sido enviada. Comprueba tu email.
              span.text-danger(v-if="error.recovery") Las contraseñas no coinciden.


        
        .row.justify-content-center.mt-3(v-if="selected==3")
          button.secondary-option(@click="toggleSelected(2)") Volver al login

      
 
</template>

<style lang="scss">
@import 'index';
</style>